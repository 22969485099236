:root {
  --main-color: #209188;
  --grey-color: #A9ADB4;
  --toastify-color-progress-success: #209188 !important;
  --toastify-icon-color-success: #209188 !important;
  --altcha-border-width: 0.2rem;
  --altcha-border-radius: 0.2rem;
  --altcha-color-border: #209188;
  --altcha-color-text: currentColor;
  --altcha-color-border-focus: currentColor;
  --altcha-color-error-text: #f23939;
  --altcha-color-footer-bg: #f4f4f4;
  --altcha-max-width: 15rem;
}

body {
  padding-top: 7rem; 
}

.main-header {
  background-color: black;
}

.main-header-brand {
  display: flex;
  align-items: start;
}

.main-header-logo {
  width: 25rem; 
  height: auto; 
}

.header {
  background-color: var(--main-color);
  margin-top: 4.2rem;
}

.arrow-icon {
  color: var(--grey-color);
}

.green-color {
  color: var(--main-color) !important; 
}

.green-background {
  background-color: var(--main-color) !important;
  color: white !important;
  border-color: var(--main-color) !important;
  font-weight: 600 !important;
}

.light-green-background {
  background-color: #F1F8FA !important;
}

.green-text {
  color: var(--main-color) !important;
  border-color: var(--main-color) !important;
  font-weight: 600 !important;
}

.text-gray {
  color: #ccc;
  font-weight: normal;
}

.text-black-semibold {
  color: #000;
  font-weight: 600;
}

.green-text-button {
  color: var(--main-color) !important;
  background-color: white !important;
  border-color: var(--main-color) !important;
  font-weight: 600 !important;
}

.white-text-button {
  color: white !important;
  background-color: var(--main-color) !important;
  border-color: var(--main-color) !important;
  font-weight: 600 !important;
}

.wide-button {
  min-width: 6rem; 
}

.min-w-cell {
  min-width: 3rem;
}

.nav-link {
  color: var(--main-color) !important;
  font-weight: 600 !important;
}

.nav-link.active {
  color: black !important;
  font-weight: 600;
}

.custom-select__option:hover {
  font-weight: 600; 
}

.custom-select__option--is-selected {
  font-weight: 600; 
}

.custom-select__option--is-focused {
  font-weight: 600; 
}

.react-datepicker__day,
.react-datepicker__day-name,
.react-datepicker__current-month,
.react-datepicker__month-text,
.react-datepicker__year-text {
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.react-datepicker__month-text {
  font-size: 14px;
}

.react-datepicker__day--highlighted {
  background-color: var(--main-color) !important; 
}

.react-datepicker__day--highlighted:hover {
  background-color: #72bbb5 !important; 
}

.react-datepicker__day--selected, 
.react-datepicker__day--keyboard-selected {
  background-color: var(--main-color) !important; 
  color: white !important;
}

.react-datepicker__month-text--highlighted {
  background-color: var(--main-color) !important; 
}

.react-datepicker__month-text--highlighted:hover {
  background-color: #72bbb5 !important; 
}

.react-datepicker__month-text--selected, 
.react-datepicker__month-text--keyboard-selected {
  background-color: var(--main-color) !important; 
  color: white !important;
}

.datepicker-overlay {
  position: absolute;
  top: 100%; 
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000; 
}

.cell-form {
  width: 7rem !important; 
  text-align: center;
}

.custom-switch .form-check-input:checked {
  background-color: var(--main-color) !important;
  border-color: var(--main-color) !important;
}

.custom-radio {
  border-color: var(--main-color) !important;
}

.large-modal {
  min-width: 95%;
} 

.medium-modal {
  min-width: 75%;
} 

.wizard-card-footer{
  display: flex;
  justify-content: end;
}

.date-picker-wrapper .react-datepicker-wrapper,
.date-picker-wrapper .react-datepicker__input-container,
.date-picker-wrapper .react-datepicker__input-container input {
  display: block;
  width: 100%;
}

.date-picker-full-width .react-datepicker-wrapper,
.date-picker-full-width .react-datepicker__input-container,
.date-picker-full-width .react-datepicker__input-container input {
  width: 100%; 
}

.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1000; 
}

.custom-form-control:focus {
  border-color: var(--main-color) !important;
  box-shadow: 0 0 0 1px var(--main-color) !important;
}

.dropdown-toggle::after {
  display: none !important; 
}

.notification-item {
  transition: background-color 0.3s ease; 
}

.notification-item.unread {
  background-color: #E3F4F3; 
  font-weight: bold; 
}

.notification-item:hover {
  background-color: var(--main-color) !important;
  color: white !important; 
}

.status-label {
  min-width: 7rem; 
  text-align: left; 
}

.accordion-button.collapsed {
  background-color: white !important;
  box-shadow: 0 0 0 1px var(--main-color) !important;
}

.accordion-button:not(.collapsed) {
  background-color: white !important;
  border-color: var(--main-color) !important;
  box-shadow: 0 0 0 2px var(--main-color) !important;
}

.accordion-button:not(.collapsed):hover {
  background-color: white !important;
  border-color: var(--main-color) !important;
  box-shadow: 0 0 0 2px var(--main-color) !important;
}

.circle {
  border: 2px solid black;
  border-radius: 50%; 
}

.circle-offuscated {
  opacity: 0.4;
}

.circle-active {
  opacity: 1;
}

.circle-clickable {
  cursor: pointer;
}

.hidden-total {
  opacity: 0;
  pointer-events: none; 
}

.intermediate-row {
  border-bottom: none; 
}

.clickable {
  cursor: pointer;
}

@media (max-width: 768px) {
  .main-header {
    justify-content: start; 
  }
  
  .main-header-logo {
    width: 18rem;
  }

  .main-header-brand {
    justify-content: start;
  }

  .header {
    margin-top: 3.5rem;
  }

  body {
    padding-top: 7rem; 
  }
}

@media (max-width: 480px) {
  .main-header-logo {
    width: 18rem; 
  }

  .header {
    margin-top: 3.5rem;
  }
}
